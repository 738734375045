import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layouts/TalkNoteLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p">{`Slides`}</strong>{`:
`}<a parentName="p" {...{
        "href": "https://pasteapp.com/p/ekfvOvdgLad/s/4GJr9oFRzhQ?view=bR00DIY9eMm"
      }}>{`https://pasteapp.com/p/ekfvOvdgLad`}</a></p>
    <hr></hr>
    <p>{`I had read like a 100 pages of different documentations, and I needed to
share things I learned in the process. I’m not sure if “100 pages” is a
hyperbole here.`}</p>
    <h2 {...{
      "id": "the-talk",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#the-talk",
        "aria-label": "the talk permalink",
        "className": "remark-autolink-headers__anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "viewBox": "0 0 24 24",
          "fill": "none",
          "stroke": "currentColor",
          "strokeWidth": "2"
        }}><path parentName="svg" {...{
            "d": "M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"
          }}></path><path parentName="svg" {...{
            "d": "M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"
          }}></path></svg></a>{`The Talk`}</h2>
    <p><strong parentName="p">{`Dan Questions`}</strong>{`:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`How will you deliver the idea to the audience?`}</p>
        <ul parentName="li">
          <li parentName="ul">{`I’ll zoom in from the problem description to a case study and add a few
jokes in-between.`}</li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li">{`What is the one thing that you want people to take away from your talk?`}</p>
        <ul parentName="li">
          <li parentName="ul">{`It’s not hard to make an app `}<em parentName="li">{`progressive`}</em>{`, but it is hard to know what
your client means by `}<em parentName="li">{`progressive`}</em>{` without talking about it.`}</li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Why are you giving the talk? What is the emotional core? What do you
believe in?`}</p>
        <ul parentName="li">
          <li parentName="ul">{`We deserve websites prepared for flaky internet connections of today.`}</li>
        </ul>
      </li>
    </ul>
    <p><strong parentName="p">{`Outline`}</strong>{`:`}</p>
    <ul>
      <li parentName="ul">{`Hi, I’m Piotr.`}</li>
      <li parentName="ul">{`There are so many articles about PWAs that people start believing in a
Progressive Web App myth.`}<ul parentName="li">
          <li parentName="ul">{`They are indeed pretty cool.`}</li>
          <li parentName="ul">{`The need they want to fill has a `}<strong parentName="li">{`long`}</strong>{` history.`}</li>
        </ul></li>
      <li parentName="ul">{`Progressive Web App is just an app that fulfils a list of requirements.`}<ul parentName="li">
          <li parentName="ul">{`You might have already implemented them without trying to build a PWA.`}</li>
          <li parentName="ul">{`You can find the checklist here: `}<a parentName="li" {...{
              "href": "https://web.dev/pwa-checklist/"
            }}>{`https://web.dev/pwa-checklist/`}</a></li>
          <li parentName="ul">{`What are these requirements and how to fulfil them`}<ul parentName="li">
              <li parentName="ul">{`Starts fast, stays fast`}</li>
              <li parentName="ul">{`Works in any browser`}</li>
              <li parentName="ul">{`Responsive to any screen size`}</li>
              <li parentName="ul">{`Provides a custom offline page`}</li>
              <li parentName="ul">{`Is installable`}</li>
            </ul></li>
          <li parentName="ul">{`Optimal Requirements`}<ul parentName="li">
              <li parentName="ul">{`Provides an offline experience`}</li>
              <li parentName="ul">{`Is fully accessible`}<ul parentName="li">
                  <li parentName="ul">{`eslint-plugin-jsx-a11y`}</li>
                  <li parentName="ul">{`react-axe (WCAG2AA and WCAG2A)`}</li>
                </ul></li>
              <li parentName="ul">{`Can be discovered through search`}</li>
              <li parentName="ul">{`Works with any input type`}</li>
              <li parentName="ul">{`Provides context for permission requests`}</li>
              <li parentName="ul">{`Follows best practices for healthy code`}</li>
            </ul></li>
        </ul></li>
      <li parentName="ul">{`How do I build a Progressive Web App?`}<ul parentName="li">
          <li parentName="ul">{`But what do we want to build? The web can do so many things today!`}<ul parentName="li">
              <li parentName="ul"><a parentName="li" {...{
                  "href": "https://whatwebcando.today"
                }}>{`https://whatwebcando.today`}</a></li>
            </ul></li>
          <li parentName="ul">{`“The PWA framework” doesn’t exist. But there are many tools. Use
anything you’re comfortable with.`}</li>
        </ul></li>
      <li parentName="ul">{`Case study of one approach (NextJS + Next Offline + Apollo Client + Offix)`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      